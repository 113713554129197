





































import { Component, Mixins } from 'vue-property-decorator'

import { Loader } from '../../../front/shared/molecules/Loader'
import { ProductCard } from '../../../front/products/organisms/ProductCard'
import { StructureConfigurable } from '../../../support/mixins'
import { SuggestedProductsMixin } from '../../../front/shared/mixins/suggestedProducts.mixin'
import {
  translateProductToProductCard
} from '../../../front/products/organisms/ProductCard/ProductCard.helpers'

import { AbstractModuleUi } from '../../abstract/ui'

import {
  GLOBAL_CONTAINER_WIDTH,
  PRODUCTS_UPSELL_COMPONENT_KEY,
  PRODUCTS_UPSELL_DEFAULT_CONFIG
} from '../ProductsUpsell.config'
import { ProductsUpsellModule } from '../ProductsUpsell.contracts'
import { CartData } from '../../../contexts'

/**
 * Container component for the `ProductsUpsellModuleUi`.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<ProductsUpsellModuleUi>({
  name: 'ProductsUpsellModuleUi',
  components: { Loader, ProductCard },
  async created (): Promise<void> {
    this.config = this.getComponentConfig(
      PRODUCTS_UPSELL_COMPONENT_KEY,
      PRODUCTS_UPSELL_DEFAULT_CONFIG
    )

    if (this.isMobile()) {
      this.defaultWidth = `${(100 / this.productsPerPage.mobile)}vw`
    } else {
      this.defaultWidth = `${(100 / this.productsPerPage.desktop)}%`
    }

    if (this.shouldFetchSuggestedProducts && this.automationMode) {
      await this.loadSuggestedProducts(this.data.content.sku, this.automationMode)
    }
  }
})
export class ProductsUpsellModuleUi extends Mixins<AbstractModuleUi<ProductsUpsellModule>, StructureConfigurable, SuggestedProductsMixin>(
  AbstractModuleUi,
  StructureConfigurable,
  SuggestedProductsMixin
) {
  public defaultWidth = 'auto'
  public showProducts = false

  /**
   * Defines typeof automation
   */
  public get automationMode (): string | undefined {
    if (this.data.source && this.data.source.options?.mode) {
      return this.data.source.options.mode
    }

    return undefined
  }

  public get carouselConfig () {
    return {
      ...this.config.carouselConfig,
      responsive: {
        ...this.config.carouselConfig.responsive,
        desktop: {
          ...this.config.carouselConfig.responsive.desktop,
          perPage: this.productsPerPage.desktop
        }
      }
    }
  }

  /**
   * Determines whether module has customClasses
   */
  public get customClass (): Array<string> {
    if (!this.data.content.customClass || this.data.content.customClass.length === 0) {
      return []
    }
    return this.data.content.customClass
  }

  /**
   * Products upsell heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  /**
   * Products upsell content
   */
  public get products () {
    if (this.shouldFetchSuggestedProducts) {
      return this.suggestedProducts ?? []
    }

    if (!this.content.products || this.content.products.length === 0) {
      return []
    }

    return this.content.products.map(p => translateProductToProductCard(p))
  }

  public get promoTitle (): string {
    return this.content?.promoTitle
  }

  public get requiredCartTotalPromo (): number | null {
    return parseFloat(this.content.requiredCartTotalPromo) || null
  }

  public get requiredProductQuantity (): number | null {
    return parseFloat(this.content.requiredProductQuantity) || null
  }

  /**
   * Determines amount of products per carousel page
   */
  public get productsPerPage () {
    return {
      mobile: this.config.carouselConfig.responsive.mobile.perPage,
      desktop: this.content.productsPerPage ??
        this.config.carouselConfig.responsive.desktop.perPage
    }
  }

  public get cart (): CartData | null {
    return this.$store.getters['checkout/cart']
  }

  public get subtotalExclTaxes (): number {
    return this?.cart?.subtotalExclTaxes?.value ?? 0
  }

  public get numberOfAvailablePromoProducts (): number {
    return (this.content.enableCartPromo && this.requiredCartTotalPromo ? Math.floor(this.subtotalExclTaxes / (this.requiredCartTotalPromo)) : 0) +
           (this.content.enableProductPromo && this.requiredProductQuantity ? Math.floor(this.numberOfRequiredProductInCart / (this.requiredProductQuantity)) : 0)
  }

  public get numberOfAddedPromoProductsInCart (): number {
    return this.products
      .map(product => product.sku)
      .reduce((total, sku) => {
        if (!sku) {
          return total
        }
        return total + (this.cart?.items.find(item => item.product.sku === sku)?.quantity ?? 0)
      }, 0)
  }

  public get numberOfRequiredProductInCart (): number {
    return this.cart?.items?.find(items => items.product.sku === this.content.productSku)?.quantity ?? 0
  }

  public get shouldEnableFeature (): boolean {
    return this.content.enableProductPromo || this.content.enableCartPromo
  }

  public get shouldEnablePromoProductsAdd (): boolean {
    if (this.content.enableCartPromo && this.subtotalExclTaxes >= (this.requiredCartTotalPromo ?? 0)) {
      return true
    }
    if (this.content.enableProductPromo && this.numberOfRequiredProductInCart >= (this.requiredProductQuantity ?? 0)) {
      return true
    }
    return false
  }

  public get shouldDisablePromoProductsAdd (): boolean {
    return this.numberOfAddedPromoProductsInCart >= this.numberOfAvailablePromoProducts
  }

  /**
   * Determines product card image width based on (container width / slides amount).
   */
  public get productCardImageWidth (): number {
    const device = this.isMobile() ? 'mobile' : 'desktop'
    return (GLOBAL_CONTAINER_WIDTH[device] / this.productsPerPage[device])
  }

  /**
   * Determines whether products in carousel should be automatically fetched (recommendations)
   */
  public get shouldFetchSuggestedProducts (): boolean {
    return this.data.source && this.data.source.options?.sku && this.data.content.sku
  }

  public toggleShowProducts (): void {
    this.showProducts = !this.showProducts
  }
}

export default ProductsUpsellModuleUi
